import { Avatar, AvatarSize } from '@pelando/components';
import React from 'react';
import { IconDefinition } from '@pelando/fontawesome';
import { useTranslation } from '../../../../hooks/useTranslation';

import { SocialWelcomeContainer } from './style';

export type SocialWelcomeProps = {
  url?: string;
  username?: string;
  badge?: IconDefinition;
  badgeLabel?: string;
};

function SocialWelcome({ username, badge, badgeLabel }: SocialWelcomeProps) {
  const { t } = useTranslation('login');
  return (
    <SocialWelcomeContainer>
      <Avatar
        badge={badge}
        alt="Avatar"
        size={AvatarSize.SMALL}
        badgeLabel={badgeLabel}
      />
      <strong>
        {t('social-network-modal-title')}
        {username ? `, ${username}` : ''}!
      </strong>
    </SocialWelcomeContainer>
  );
}

export default SocialWelcome;
