import styled, { css } from 'styled-components';
import {
  Button,
  Colors,
  FontFamily,
  MediaQuery,
  Small,
} from '@pelando/components';

export const FormInputContainer = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const FormInputButton = styled(Button)`
  margin-top: 8px;
  height: 48px;
  width: 100%;

  @media ${MediaQuery.SCREEN_LG_UP} {
    width: 340px;
  }
`;

export const FormInputOptional = styled.span`
  font-size: 14px;
  color: rgb(${Colors.Gray});
  text-align: center;
  margin-bottom: 4px;
`;

export const FormInputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  div {
    width: 100%;
  }
`;

const TextInputPrefixSuffixText = css`
  font-size: 20px;
  font-weight: 700;
  font-family: ${FontFamily.DM_SANS};
`;

export const FormInputFieldPrefix = styled.span`
  ${TextInputPrefixSuffixText}

  margin-right: 4px;
`;

export const FormInputFieldSuffix = styled.span`
  ${TextInputPrefixSuffixText}

  margin-left: 4px;
`;

export const FormInputErrorMessageContainer = styled.span`
  ${Small}
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-top: 4px;
  color: rgb(${Colors.Red});

  i {
    margin-right: 4px;
    font-size: 24px;
  }
`;

export const TextInputContainer = styled.div`
  display: flex;
  width: 100%;
`;
