import React from 'react';
import { Facebook } from '@pelando/fontawesome/icons';
import { useTranslation } from '../../../../hooks/useTranslation';

import { FacebookLoginData } from '../../types';
import SocialWelcome from '../SocialWelcome';
import StepHeader, { StepHeaderType } from '../StepHeader';
import {
  SocialEmailConfirmationContainer,
  SocialEmailConfirmationFormInput,
} from './style';

export type SocialEmailConfirmationProps = {
  user: FacebookLoginData['user'];
  onSubmit: (email: string) => void;
  customError?: string;
  onGoBack: () => void;
};

function SocialEmailConfirmation({
  onSubmit,
  user,
  customError,
  onGoBack,
}: SocialEmailConfirmationProps) {
  const { t } = useTranslation('login');
  return (
    <SocialEmailConfirmationContainer>
      <StepHeader onButtonClick={onGoBack} type={StepHeaderType.GO_BACK} />
      <SocialWelcome
        url={user.image}
        badge={Facebook}
        badgeLabel="Ícone Facebook"
        username={user.name}
      />
      <SocialEmailConfirmationFormInput
        placeholder="ex.: rafa@gmail.com"
        title={t('login-reset-pass-email')}
        defaultValue={user.email}
        required
        onSubmit={() => onSubmit}
        validations={{
          rules: {
            email: true,
          },
          errorMessage: t('login-error-message-invalid-email'),
        }}
        btnText={t('login-step-continue-button')}
        customError={customError}
      />
    </SocialEmailConfirmationContainer>
  );
}

export default SocialEmailConfirmation;
